@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;500;600;700;900&display=swap');

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}


/* MAIN SCREEN */
.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height:0;
}

/* NAVBAR */
.navbar {
  height: 9vh;
  box-shadow: 0px 1px 2px rgb(190, 190, 190);
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height:0;
}

/* NAVBAR: LOGO */
.navbar .logo {
  padding-left: 0.5%; /* 2% Beirut Cleanup logo */
}

.navbar .logo img {
  cursor: pointer;
  max-width: 19vw; /* 13vw Beirut Cleanup logo */
  display: inline;
}

/* NAVBAR: BUTTONS */
.navbar .navigation {
  padding-right: 2%;
  display: flex;
}

.navbar .navigation #addevent, #donate {
  cursor: pointer;
  font-weight: 900;
  font-size: .8rem;
  border-radius: 3px;
  margin-left: 1.2vw;
}

.navbar .navigation #addevent {
  color: white;
  background-color: #121459;
  border: 1px solid #121459;
  padding: 4px 11px;
  box-shadow: 0px 2px 3px rgb(190, 190, 190);
}

.navbar .navigation #donate {
  color: #121459;
  background-color: transparent;
  border: 1px solid #d6d6d6;
  padding: 4px 20px;
  box-shadow: 0px 1px 3px rgb(190, 190, 190);
}

/* NAVBAR: SEARCH BAR */
.searchbar {
  position: absolute;
  top: 1.4vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  border-bottom: 4px solid #0F1146;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchbar input {
  background-color: transparent;
  font-size: 1.1vw;
  padding: 1.3vh .3vw;
  width: 18vw;
  border: none;
}

.searchbar input:focus {
  outline: none;
}

.searchbar button {
  width: 1.2vw;
  height: 1.2vw;
  border: none;
  background-color: transparent;
  background-image: url("../Assets/search.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;  
}

/* INNER CONTAINER */
.inner-container {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  height: 100%;
  min-height:0;
}

/* MAP */
.mainmap {
  flex-basis: 60%;
  height: 100%;
}

/* SIDEBAR */
.sidebar {
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  max-width: 40vw; /* new addition for whitespace */
  background-color: #EFF2F5;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  z-index: 2;
  box-shadow: 1px 5px 5px rgb(190, 190, 190);
  min-height:0;
}

/* SIDEBAR: HEADING */
.sidebar .heading {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5vh 0 5vh 0;
  background-color: #0e1046;
  box-shadow: 0px 5px 3px rgba(99, 99, 99, 0.377);
  z-index: 2;
  min-height:0;
}

.sidebar .heading h1 {
  font-size: 2.1vw;
  font-weight: 700;
  color: white;
  border-bottom: 4px white solid;
}

.sidebar .heading h6 {
  margin-top: 3px;
  color: white;
  font-size: 1.4vw;
  font-weight: 900;
}

/* SIDEBAR: BODY */
.sidebar .body {
  overflow: scroll;
  margin-top: 2px;
  flex-grow: 1;
}

.sidebar .cell {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 27vh;
  color: #353A4D;
  padding: 1.2vh 1.2vw 1.2vh 1.2vw;
  margin: 1vw .6vw;
  background-color: white;
  box-shadow: 0px 5px 5px rgb(190, 190, 190);
  border-radius: 3px;
}

.sidebar .cell .eventtext {
  flex-grow: 1;
  overflow: hidden;
  padding-bottom: 3vw;
}

.sidebar .cell .eventtext h4 {
  font-size: .8vw;
  color: rgb(153, 153, 153);
  margin: .4vw 0;
  font-weight: 500;
}

.sidebar .cell .eventtext h1 {
  font-size: 1.6vw;
  font-weight: 500;
  color: black;
}

.sidebar .cell .eventtext p {
  font-size: 14px;
  line-height: 2.1vh;
  color: #848484;
}

.sidebar .cell .moreinformationbutton {
  z-index: 3;
  margin-top: 5%;
}

.sidebar .cell .moreinformationbutton button {
  cursor: pointer;
  width: 100%;
  height: 40px;
  background-color: #121459;
  border: 0;
  box-shadow: 0px 2px 2px rgb(190, 190, 190);
  border-radius: 3px;
  color: white;
  font-weight: 900;
  font-size: 1vw;
}

.sidebar .cell .texttruncationfadeeffect {
  position: absolute;
  width: 96%; /* Somewhat iffy measurements for text fade */
  height: 75%;
  background: linear-gradient(to bottom, transparent, transparent, transparent, transparent,  white 85%);
}

.sidebar .cell .miniLine {
  margin: .5vw 0;
  width: 30px;
  height: 2px;
  background-color: #EBEBEB;
}

/* INFO WINDOW */
.infowindow {
  font-family: 'Roboto', sans-serif;
}

.infowindow .title {
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  font-size: 14px;
}

.infowindow .divider {
  height: 1px;
  width: 100%;
  margin: 2px auto;
  background-color: #EBEBEB;
}

.infowindow .date {
  background-color: #121459;
  color: white;
  padding: 2px 2px;
  font-weight: 900;
  font-size: 10px;
}

.infowindow .description {
  display: block;
  overflow: scroll;
  color: #313131;
  font-size: 14px;
  max-height: 4rem;
  line-height: 1em;
  border-bottom: 1px solid #EBEBEB;
  cursor: pointer;
}

/* MORE INFORMATION */
.moreinformation {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 2%;
  box-shadow: 0px 5px 5px rgb(190, 190, 190);
  min-height: 95%;
}

.moreinformation .exitbutton button {
  align-self: right;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 700;
  font-size: 1.1vw;
  color: #7c7c7c;
  cursor: pointer;
  float: right;
  margin: 2vh .8vw 0 0;
  border: 0px;
  background-color: inherit;
}

.moreinformation .information {
  display: flex;
  padding: 0 5%;
  flex-direction: column;
}

.moreinformation .information h1 {
  align-self: center;
  font-size: 2.3vw;
  font-weight: 150;
}

.moreinformation .information div {
  align-self: center;
  width: 100%;
  height: 1px;
  background-color: #EBEBEB;
  margin: 2vh 0;
}

.moreinformation .information p,span {
  white-space: pre-wrap;
  font-weight: 150;
  font-size: 1vw;
  line-height: 2.5vh;
  color: rgb(43, 43, 43);
}

.moreinformation .information .date {
  font-weight: 500;
  font-size: .8vw;
}









/* CREATE EVENT MAP */
.createmap {
  width: 100%;
  height: 100%;
}

/* CREATE EVENT FORM */
.form {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 1%;
  margin: 3vh;
  height: 82vh;
  width: 30vw;
  min-width: 300px;
  background-color: white;
  box-shadow: 0px 4px 10px #999;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 1.1vw;
}

.form ::placeholder {
  font-size: 1.1vw;
}

.form .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: inherit;
}

.form .info h1 {
  font-size: 2.3vw;
  font-weight: 700;
  border-bottom: 6px #161a92 solid;
  margin-bottom: 1.2vh;
  margin-top: 3vh; 
}

.form .info input {
  height: 4.4vh;
  width: 90%;
  border: 1px solid rgb(210, 210, 210);
  border-radius: 4px; 
  margin: 2% 0;
  font-family: inherit;
  font-size: 1.1vw;
}

.form .description {
  flex-grow: 1;
}

.form .description textarea {
  resize: none;
  width: 90%;
  margin: 2% 0;
  height: 100%;
  font-family: inherit;
  font-size: 1.1vw;
  border: 1px solid rgb(210, 210, 210);
  border-radius: 2px; 
}

.form #location {
  text-align: center;
  border: none;
  background-color: white;
  border-right: rgb(200, 200, 200) 2px solid;
  border-left: rgb(200, 200, 200) 2px solid;
  border-radius: 5px;
  font-size: .95vw;
  font-weight: 400;
  color: black;
}

.form #location::placeholder {
  font-size: .95vw;
  font-weight: 300;
}

.form .submit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form .submit button {
  cursor: pointer;
  width: 90%;
  height: 45px;
  border: 0;
  border-radius: 5px;
  background-color: #121459;
  font-size: 90%;
  font-weight: 900;
  color: white;
  box-shadow: 0px 2px 2px rgb(190, 190, 190);
}









/* ADMIN LOGIN */
.admin-login-main-container {
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #43425D;
  height: 100vh;
  width: 100vw;
  background: url("../Assets/loginbackground.jpg");
  background-size: cover;
  background-position: center;
}

.admin-login-inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 325px;
  height: 450px;
  border-radius: 2px;
  box-shadow: 0px 11px 20px 8px #221e4198;
  background-color: #bebebe80;
}

.admin-login-vertical-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 235px;
  height: 100%;
  background-color: transparent;
}

.admin-login-vertical-container img {
  margin-top: 70px;
  width: 200px;
}

.admin-login-form {
  display: flex;
  flex-direction: column;
  margin-top: 55px;
  width: 100%;
}

.admin-login-form h5 {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 8px;
  color: white;
}

.admin-login-form input {
  height: 22px;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 13px;
  padding-bottom: 4px;
  border-bottom: 2px solid white;
  margin-bottom: 5px;
}

.admin-login-form input::placeholder {
  color: rgb(180, 180, 180);
}

.admin-login-form input:focus {
  outline: none;
}

.admin-login-form h6 {
  font-size: 10px;
  font-weight: 700;
  color: #121459;
  margin-bottom: 11px;
}

.admin-login-form button.submit {
  cursor: pointer;
  width: 100%;
  margin-top: 15px;
  height: 33px;
  background-color: #121459;
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 2px;
}

.admin-login-vertical-container button.request {
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin-top: 22px;
  text-align: center;
  font-size: 12px;
  color: white;
}









/* ADMIN DASHBOARD */
.admin-dashboard-outer-container {
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

/* HEADER */
.admin-dashboard-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  height: 8vh;
  background-color: #333B3F;
  z-index: 1;
  box-shadow: 0px 1px 6px rgb(39, 39, 39);
}

.admin-dashboard-header h1 {
  cursor: pointer;
  color: white;
  margin-left: 1vw;
  font-size: 1.5vw;
  font-weight: 450;
}

.admin-dashboard-header .exit {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.admin-dashboard-header .exit h6 {
  color: rgba(255, 255, 255, 0.5);
  margin-left: 1vw;
  font-size: 14px;
  font-weight: 400;
  padding-right: 3px;
}

.admin-dashboard-header .exit img {
  opacity: .5;
  padding-right: 3vw;

}

/* INNER CONTAINER */
.admin-dashboard-inner-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

/* SIDEBAR */
.admin-dashboard-sidebar {
  height: 100%;
  flex-basis: 15%;
  background-color: #212629;
  position: relative;
}

.admin-dashboard-sidebar label {
  color: #585C5F;
  font-weight: 900;
  font-size: 13px;
  display: block;
  margin: 4.5vh 0;
  margin-left: 1vw;
}

.admin-dashboard-sidebar .section {
  display: flex;
  color: white;
  align-items: center;
  width: 100%;
  margin-bottom: 28px;
}

.admin-dashboard-sidebar .link {
  text-decoration: none;
}

.admin-dashboard-sidebar .section img {
  cursor: pointer;
  margin-left: 1vw;
  opacity: .5;
}

.admin-dashboard-sidebar .section button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 700;
  font-size: 16px;
  flex-grow: 1;
  text-align: left;
  outline: none;
}

.admin-dashboard-sidebar .section h6 {
  padding-right: 15px;
  font-size: 20px;
  font-weight: 500;
}

.admin-dashboard-sidebar .activelink img {
  opacity: 1 !important;
}

.admin-dashboard-sidebar .activelink button {
  color: rgba(255, 255, 255) !important;
} 

.admin-dashboard-sidebar .userinfo {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #333B3F;
  color: #999D9F;
  padding: 1.3vw 0;
}

.admin-dashboard-sidebar .userinfo h5 {
  font-size: 13px;
  padding: 0 0 1vh 1vw;
}

.admin-dashboard-sidebar .userinfo h4 {
  font-size: 16px;
  font-weight: 500;
  padding-left: 1vw;
}

/* MAINCONTENT */
.admin-dashboard-maincontent {
  height: 100%;
  flex-basis: 85%;
}









/* EVENT MAIN SCREEN */
.event-outer-container {
  width: 100%;
  height: 100%;
  background-color: #f2f3f5;
}

.event-inner-container {
  padding: 5% 3%;
}

/* EVENT: HEADING */
.event-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: .3%;
  border-bottom: 1px solid #CCCFD8;
}

.event-heading .title {
  color: #353A4D;
  font-size: 2.5vw;
  font-weight: 400;
}

.event-heading .add-event-button {
  cursor: pointer;
  border: 0;
  padding: 10px 10px;
  color: white;
  background-color: #121459;
  border-radius: 5%;
  font-weight: 900;
  font-size: .8rem
}

/* EVENT: TABLE */
.event-table {
  display: flex;
  width: 100%;
  margin-top: 2%;
  table-layout: fixed;
  text-align: left;
  background-color: white;
  box-shadow: 0px 1px 3px #999;
  color: #353A4D;
}

.event-table thead {
  border-bottom: 1px solid #CCCFD8;
}

.event-table tbody, .event-table thead {
  display: block;
}

.event-table tbody {
  overflow-y: scroll;
  height: 60vh;
}

.event-table tbody tr:nth-child(even) {
  background-color: #FBFBFB;
}

.event-table th, .event-table td {
  padding: 15px 1vw;
}

.event-table th {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 13px;
  font-weight: 600;
}


.event-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  font-weight: 500;
}

.event-table .event-name {
  width: 8vw;
  max-width: 8vw;
  padding: 1vw;

}

.event-table .event-date {
  width: 10vw;
  max-width: 10vw;
  padding-right: 1vw;
}

.event-table .event-description {
  width: 45vw;
  max-width: 45vw;
  padding-right: 1vw;
}

.event-table .event-buttons {
  width:10vw;
  max-width: 10vw;
  display: flex;
  flex-direction: row;
}

.event-buttons button {
  height: 30px;
  font-weight: 900;
  font-size: .8vw;
  color: #353A4D;
  padding: 0 15px;
}

.edit-button {
  cursor: pointer;
  background-color: white;
  border-radius: 5%;
  border: 1px solid #CCCFD8;
}

.delete-button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}









/* EVENT CREATE SCREEN */
.eventcreatemap {
  width: 100%;
  height: 100%;
}

/* EVENT: SEARCHBAR */
.eventsearchbar {
  position: absolute;
  z-index: 10;
  margin-top: 4vh;
  margin-right: 14vw;
  right: 0;
  border-bottom: 2px solid #0f114662;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eventsearchbar input {
  font-size: 15px !important;
  width: 10vw;
  height: 2vh;
}

.eventsearchbar button {
  width: 5vw;
  height: 2.5vh;
}

.eventsearchbar input {
  background-color: transparent;
  font-size: 15px;
  padding: 1.3vh .3vw;
  width: 18vw;
  border: none;
}

.eventsearchbar input:focus {
  outline: none;
}

.eventsearchbar button {
  width: 1.2vw;
  height: 1.2vw;
  border: none;
  background-color: transparent;
  background-image: url("../Assets/search.png");
  opacity: .5;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;  
}

/* EVENT: CREATE FORM */
.eventform {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 1%;
  margin: 3vh;
  height: 81vh;
  width: 30vw;
  min-width: 300px;
  background-color: white;
  box-shadow: 0px 4px 10px #999;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 1.1vw;
  z-index: 3;
}

.eventform ::placeholder {
  font-size: 1.1vw;
}

.eventform .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: inherit;
}

.eventform .info h1 {
  font-size: 2.3vw;
  font-weight: 700;
  border-bottom: 6px #161a92 solid;
  margin-bottom: 1.2vh;
  margin-top: 3vh; 
}

.eventform .info input {
  height: 4.4vh;
  width: 90%;
  border: 1px solid rgb(210, 210, 210);
  border-radius: 4px; 
  margin: 2% 0;
  font-family: inherit;
  font-size: 1.1vw;
}

.eventform .description {
  flex-grow: 1;
}

.eventform .description textarea {
  resize: none;
  width: 90%;
  margin: 2% 0;
  height: 100%;
  font-family: inherit;
  font-size: 1.1vw;
  border: 1px solid rgb(210, 210, 210);
  border-radius: 2px; 
}

.eventform #location {
  text-align: center;
  border: none;
  background-color: white;
  border-right: rgb(200, 200, 200) 2px solid;
  border-left: rgb(200, 200, 200) 2px solid;
  border-radius: 5px;
  font-size: .95vw;
  font-weight: 400;
  color: black;
}

.eventform #location::placeholder {
  font-size: .95vw;
  font-weight: 300;
}

.eventform .buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.eventform #submit, .eventform #back {
  cursor: pointer;
  width: 43%;
  height: 45px;
  border: 0;
  border-radius: 5px;
  font-size: 90%;
  font-weight: 900;
  box-shadow: 0px 2px 2px rgb(190, 190, 190);
}

.eventform #submit {
  background-color: #121459;
  color: white;
}

.eventform #back {
  background-color: rgba(209, 209, 209, 0.041);
  color: #121459;
  border: 2px solid rgb(228, 228, 228);
  box-shadow: none;
}






/* ACCOUNT MAIN SCREEN */
.accounts-body {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2%;
  height: 60vh;
}

/* ACCOUNT: CREATE ACCOUNT */
.accounts-form-outer-container {
  display: flex;
  justify-content: center;
  width: 25vw;
  background-color: white;
  box-shadow: 0px 1px 3px #999;
}

.accounts-form-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
}

.accounts-form-inner-container .formheading {
  margin: 7vh 0 5vh 0;
  padding: 10px 0;
  width: 100%;
  border-top: 1px solid #CCCFD8;
  border-bottom: 1px solid #CCCFD8;
}

.accounts-form-inner-container .formheading h1 {
  text-align: center;
  color: #121459e8;
  font-size: 25px;
}

.accounts-form-inner-container .forminfo {
  width: 100%;
}

.accounts-form-inner-container .forminfo div {
  margin-bottom: 3vh;
}

.accounts-form-inner-container .forminfo h6 {
  padding: 3px 0;
  font-size: 10px;
  color: #323235c4;
  font-weight: 800;
}

.accounts-form-inner-container .forminfo input {
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #CCCFD8;
  background-color: transparent;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 500;
  height: 30px;
}

.accounts-form-inner-container .formsubmit {
  padding: 2vh 0;
  width: 100%;
}

.accounts-form-inner-container .formsubmit button {
  cursor: pointer;
  width: 100%;
  height: 35px;
  border: 0;
  border-radius: 2px;
  background-color: #121459;
  font-size: 90%;
  font-weight: 900;
  color: white;
  box-shadow: 0px 2px 2px rgb(190, 190, 190);

}


/* ACCOUNT: ACCOUNT TABLE */
.accounts-table {
  display: flex;
  width: 52vw;
  background-color: white;
  box-shadow: 0px 1px 3px #999;
  height: 100%;
}

.accounts-table table {
  margin-top: 2%;
  width: 100%;
  table-layout: fixed;
  text-align: left;
  color: #353A4D;
}

.accounts-table thead {
  border-bottom: 1px solid #CCCFD8;
}

.accounts-table tbody, .accounts-table thead {
  display: block;
}

.accounts-table tbody {
  overflow-y: scroll;
  width: 100%;
  height: 50vh;
}

.accounts-table tbody tr:nth-child(even) {
  background-color: #FBFBFB;
  width: 100%;
}

.accounts-table th, .accounts-table td {
  padding: 15px 1vw;
}

.accounts-table th {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 13px;
  font-weight: 600;
}

.accounts-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  font-weight: 500;
}

.accounts-table .account-name {
  width: 10vw;
  max-width: 10vw;
  padding: 1vw;
}

.accounts-table .account-email {
  width: 22vw;
  max-width: 22vw;
  padding-right: 1vw;
}

.accounts-table .account-privileges {
  width: 6vw;
  max-width: 6vw;
  padding-right: 1vw;
}

.accounts-table .account-locked {
  display: flex;
  width:5vw;
  max-width:5vw;
  justify-content: center;
  align-items: center;
}








/* MISCELLANEOUS */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version (supported by Chrome, Edge, Opera and Firefox) */
}




/* INNER CONTAINER */
/*
.inner-container {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  height: 100%;
  min-height:0;
}

/* MAP */
/*
.mainmap {
  flex-basis: 60%;
  height: 100%;
}
*/
/*
.sidebar {
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  max-width: 40vw; /* new addition for whitespace */
  /*
  background-color: #EFF2F5;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  z-index: 2;
  box-shadow: 1px 5px 5px rgb(190, 190, 190);
  min-height:0;
}
*/


@media only screen and (max-width: 600px) {

  * {
    font-size: 13px !important;
  }

  html {
    touch-action: none !important;
    overflow-y: hidden;
    height: -webkit-fill-available !important;
    min-height: -webkit-fill-available !important;
  }

  body {
    touch-action: none !important;
    overflow-y: hidden;
    min-height: 100vh !important;
    height: -webkit-fill-available !important;
    min-height: -webkit-fill-available !important;
    background-color: #EFF2F5;
  }

  /* MAIN CONTAINER */
  .main-container {
    touch-action: none !important;
    min-height: 100vh !important;
    min-height: -webkit-fill-available !important;
  }

  /* NAV BAR */
  .navbar {
    background-color: white;
    height: 8.5vh;
  }

  /* nav bar: logo */
  .navbar .logo img {
    display: none;
  }

  /* nav bar: search */
  .searchbar {
    position: fixed;
    border-bottom: 0;
    padding: 0 2vw;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid #cfcfcf;
  }

  .searchbar input {
    font-size: 15px !important;
    width: 85vw;
  }

  .searchbar button {
    width: 4vw;
    height: 4vw;
  }

  /* nav bar: buttons */
  .navbar .navigation #donate, #addevent {
    display: none;
  }

  /* INNER CONTAINER */
  .inner-container {
    flex-direction: row !important;
    flex-wrap: wrap-reverse !important;
    height: 100%;
  }

  /* MAIN MAP */
  .mainmap {
    width: 100vw;
    flex-basis: auto;
    height: 50%;
  }
  
  /* SIDEBAR */
  .sidebar {
    width: 100vw;
    max-width: 100vw;
    flex-basis: auto;
    height: 50%;
    box-shadow: none;

  }

  .sidebar .heading {
    padding: 2vh 0;
    box-shadow: 0px 5px 3px rgba(179, 179, 179, 0.377);
  }

  .sidebar .heading h1 {
    display: none;
  }

  .sidebar .body {
    padding-bottom: 13vh;
  }

  .sidebar .cell {
    height: 22vh;
  }

  .sidebar .cell .texttruncationfadeeffect {
    display: none;
  }
}




/* MOBILE SCREENS */
/* COMMENTED 
@media only screen and (max-width: 600px) {

  * {
    font-size: 15px !important;
  }


  /* MAIN SCREEN */
  /* COMMENTED
  .main-container {
    position: relative;
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }

  .sidebar {
    display: none;
  }

  .mainmap {
    flex-basis: 100%;
  }

  .navbar .logo {
    display: none;
  }

  .navbar .navigation #donate {
    display: none;
  }

  .searchbar {
    left: 3%;
    transform: unset;

  }

  .searchbar input {
    font-size: 15px !important;
    width: 50vw;
    height: 2vh;
  }

  .searchbar button {
    width: 5vw;
    height: 2.5vh;
  }

  /* CREATE SCREEN */
  /* COMMENTED
  .create {
    display: flex !important;
    flex-direction: column !important;
    flex-basis: unset !important;
  }
  /*
.inner-container {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  height: 100%;
  min-height:0;
}
  */
  /* COMMENTED
  .createmap {
    height: 50%;
  }
  /*
.createmap {
  width: 100%;
  height: 100%;
}
  */
  /* COMMENTED
  .form {
    position: static;
    height: 60%;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  /*
  .form {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 1%;
  margin: 3vh;
  height: 82vh;
  width: 30vw;
  min-width: 300px;
  background-color: white;
  box-shadow: 0px 4px 10px #999;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 1.1vw;
}
*/
  /* COMMENTED
  .form ::placeholder {
    font-size: 15px !important;
  }
}
*/